import {
  BUTTONS_STYLE,
  hasGuestsGoing,
  hasImage,
  IMAGE_RATIO,
  IMAGE_SCALING,
  isInternal,
  isRecurringEvent,
  isRsvp,
  isWithoutRegistration,
  LIST_LAYOUT,
  LOCATION_AND_DATE_FORMAT,
  MOBILE_LIST_LAYOUT,
  WIDGET_LAYOUT,
  WIDGET_TYPE,
} from '@wix/wix-events-commons-statics'
import {isEditor, isMobile} from '../../../../commons/selectors/environment'
import {State, WidgetComponentConfig} from '../types/state'
import {getFirstEvent} from './events'

export const isResponsive = (componentConfig: WidgetComponentConfig) => componentConfig.settings.responsive

export const getImageWidth = (componentConfig: WidgetComponentConfig) => componentConfig.settings.imageWidth

export const getImageGridPosition = (componentConfig: WidgetComponentConfig) =>
  componentConfig.settings.imageGridPosition

export const getMultiImageRatio = (componentConfig: WidgetComponentConfig, mobile = false) => {
  if (getValue(componentConfig, mobile, 'multiImageRatio')) {
    return getValue(componentConfig, mobile, 'multiImageRatio')
  }
  if (mobile) {
    const mobileImageRatio = getMobileOnlyValue(componentConfig, 'imageRatio')
    if (mobileImageRatio) {
      return mobileImageRatio
    }
  }
  if (isCardsLayout(componentConfig) && getValue(componentConfig, false, 'imageRatio')) {
    return getValue(componentConfig, false, 'imageRatio')
  }

  if (isCalendarLayout(componentConfig) || (mobile && !isCardsLayout(componentConfig))) {
    return IMAGE_RATIO['16:9']
  }
  return IMAGE_RATIO['1:1']
}

export const getImagePosition = (componentConfig: WidgetComponentConfig) => componentConfig.settings.imagePosition

export const getVerticalImagePosition = (componentConfig: WidgetComponentConfig) =>
  componentConfig.settings.verticalImagePosition

export const getAlignment = (componentConfig: WidgetComponentConfig) => componentConfig.settings.alignment

export const getContentAlignment = (componentConfig: WidgetComponentConfig) => componentConfig.settings.contentAlignment

export const getOneButtonWidth = (componentConfig: WidgetComponentConfig) => componentConfig.settings.oneButtonWidth

export const getButtonHeight = (componentConfig: WidgetComponentConfig) => componentConfig.settings.buttonHeight

export const getButtonBorderRadius = (componentConfig: WidgetComponentConfig) =>
  componentConfig.settings.buttonsBorderRadius

export const getRoundedButtonBorderRadius = (componentConfig: WidgetComponentConfig) =>
  componentConfig.settings.roundedButtonsBorderRadius

export const getListImagePosition = (componentConfig: WidgetComponentConfig) =>
  componentConfig.settings.listImagePosition

export const getListImageWidth = (componentConfig: WidgetComponentConfig) => componentConfig.settings.listImageWidth

export const getListStripWidth = (componentConfig: WidgetComponentConfig, mobile = false): number =>
  getValue(componentConfig, mobile, 'listStripWidth')

export const getCardLocationAndDateFormat = (componentConfig: WidgetComponentConfig) =>
  componentConfig.settings.cardLocationAndDateFormat

export const getCardMargins = (componentConfig: WidgetComponentConfig, mobile?: boolean) =>
  getValue(componentConfig, mobile, 'cardMargins')

export const getSideBySideLayoutMargins = (componentConfig: WidgetComponentConfig, mobile?: boolean) =>
  getValue(componentConfig, mobile, 'sideBySideLayoutMargins')

export const getCalendarWeekStartDay = (componentConfig: WidgetComponentConfig) =>
  componentConfig.settings.calendarWeekStartDay

export const getTodayButtonStyle = (componentConfig: WidgetComponentConfig) => componentConfig.settings.todayButtonStyle

export const getVerticalDividerWidth = (componentConfig: WidgetComponentConfig) =>
  componentConfig.settings.verticalDividerWidth

export const getVerticalDividerHeight = (componentConfig: WidgetComponentConfig) =>
  componentConfig.settings.verticalDividerHeight

export const getHorizontalDividerWidth = (componentConfig: WidgetComponentConfig) =>
  componentConfig.settings.horizontalDividerWidth

export const getHorizontalDividerHeight = (componentConfig: WidgetComponentConfig) =>
  componentConfig.settings.horizontalDividerHeight

export const getCompactHorizontalDividerWidth = (componentConfig: WidgetComponentConfig) =>
  componentConfig.settings.compactHorizontalDividerWidth

export const getCompactHorizontalDividerHeight = (componentConfig: WidgetComponentConfig) =>
  componentConfig.settings.compactHorizontalDividerHeight

export const getListLayout = (componentConfig: WidgetComponentConfig) => componentConfig.settings.listLayout

export const getMobileListLayout = (componentConfig: WidgetComponentConfig) =>
  getValue(componentConfig, true, 'mobileListLayout')

export const isCompactMobileLayout = (componentConfig: WidgetComponentConfig) =>
  isMultiEventWidget(componentConfig) && getMobileListLayout(componentConfig) === MOBILE_LIST_LAYOUT.COMPACT

export const isHorizontalLayout = (componentConfig: WidgetComponentConfig) =>
  isSingleEventWidget(componentConfig) && getWidgetLayout(componentConfig) === WIDGET_LAYOUT.FULL

export const isListLayout = (componentConfig: WidgetComponentConfig) =>
  isMultiEventWidget(componentConfig) && getListLayout(componentConfig) === LIST_LAYOUT.LIST

export const isCardsLayout = (componentConfig: WidgetComponentConfig) =>
  isMultiEventWidget(componentConfig) &&
  (getListLayout(componentConfig) === LIST_LAYOUT.NEW_GRID || getListLayout(componentConfig) === LIST_LAYOUT.GRID)

export const isCalendarLayout = (componentConfig: WidgetComponentConfig) =>
  isMultiEventWidget(componentConfig) && getListLayout(componentConfig) === LIST_LAYOUT.CALENDAR

export const isListMainTitleEnabled = (componentConfig: WidgetComponentConfig, mobile: boolean) =>
  getValue(componentConfig, mobile, 'listShowMainTitle')

export const isListDateEnabled = (componentConfig: WidgetComponentConfig, mobile: boolean) =>
  getValue(componentConfig, mobile, 'listShowDate')

export const isListVenueNameEnabled = (componentConfig: WidgetComponentConfig) =>
  componentConfig.settings.listShowVenueName

export const isListFullDateEnabled = (componentConfig: WidgetComponentConfig, mobile: boolean) =>
  getValue(componentConfig, mobile, 'listShowFullDate')

export const isListLocationEnabled = (componentConfig: WidgetComponentConfig, mobile) =>
  getValue(componentConfig, mobile, 'listShowLocation')

export const isListDescriptionEnabled = (componentConfig: WidgetComponentConfig, mobile: boolean) =>
  getValue(componentConfig, mobile, 'listShowDescription')

export const isListSocialShareEnabled = (componentConfig: WidgetComponentConfig, mobile: boolean) =>
  getValue(componentConfig, mobile, 'listShowSocialShare')

export const isListImageEnabled = (componentConfig: WidgetComponentConfig, mobile: boolean) =>
  getValue(componentConfig, mobile, 'listShowImage')

export const isListMembersElementEnabled = (componentConfig: WidgetComponentConfig, mobile: boolean) =>
  getValue(componentConfig, mobile, 'listShowMembers')

export const isListAdditionalInfoEnabled = (componentConfig: WidgetComponentConfig, mobile: boolean) =>
  getValue(componentConfig, mobile, 'listShowAdditionalInfo')

export const isHollowButton = (componentConfig: WidgetComponentConfig) =>
  [BUTTONS_STYLE.HOLLOW, BUTTONS_STYLE.HOLLOW_ROUNDED].includes(componentConfig.settings.buttonsStyle)

export const isRoundedButton = (componentConfig: WidgetComponentConfig) =>
  [BUTTONS_STYLE.FULL_ROUNDED, BUTTONS_STYLE.HOLLOW_ROUNDED].includes(componentConfig.settings.buttonsStyle)

export const isFitImage = (componentConfig: WidgetComponentConfig, mobile = false) =>
  (isMultiEventWidget(componentConfig)
    ? getValue(componentConfig, mobile, 'multiImageScaling')
    : componentConfig.settings.imageScaling) === IMAGE_SCALING.FIT

export const isMultiEventWidget = (componentConfig: WidgetComponentConfig) =>
  getWidgetType(componentConfig) === WIDGET_TYPE.LIST

/**
 * @deprecated Should only be used as legacy fallback for more specific ribbons
 */
export const isStatusRibbonEnabled = (componentConfig: WidgetComponentConfig, mobile: boolean) =>
  getValue(componentConfig, mobile, 'showRibbon')

export const isJoinWaitlistRibbonEnabled = (componentConfig: WidgetComponentConfig, mobile: boolean) =>
  getValue(componentConfig, mobile, 'showJoinWaitlistRibbon') ?? isStatusRibbonEnabled(componentConfig, mobile)

export const isSoldOutRibbonEnabled = (componentConfig: WidgetComponentConfig, mobile: boolean) =>
  getValue(componentConfig, mobile, 'showSoldOutRibbon') ?? isStatusRibbonEnabled(componentConfig, mobile)

export const isAnyStatusRibbonEnabled = (componentConfig: WidgetComponentConfig, mobile: boolean) =>
  isSoldOutRibbonEnabled(componentConfig, mobile) ||
  isJoinWaitlistRibbonEnabled(componentConfig, mobile) ||
  isRsvpClosedRibbonEnabled(componentConfig, mobile)

export const getWidgetType = (componentConfig: WidgetComponentConfig) => componentConfig.settings.widgetType

export const isSingleEventWidget = (componentConfig: WidgetComponentConfig) =>
  getWidgetType(componentConfig) === WIDGET_TYPE.SINGLE

export const getWidgetLayout = (componentConfig: WidgetComponentConfig) => componentConfig.settings.widgetLayout

export const isRsvpClosedRibbonEnabled = (componentConfig: WidgetComponentConfig, mobile: boolean) =>
  getValue(componentConfig, mobile, 'showRsvpClosedRibbon') ?? isStatusRibbonEnabled(componentConfig, mobile)

export const isVerticalLayout = (componentConfig: WidgetComponentConfig) =>
  isSingleEventWidget(componentConfig) && getWidgetLayout(componentConfig) === WIDGET_LAYOUT.VERTICAL

export const isVerticalDateAndLocation = (componentConfig: WidgetComponentConfig) =>
  componentConfig.settings.locationAndDateFormat === LOCATION_AND_DATE_FORMAT.VERTICAL

export const isTitleEnabled = (componentConfig: WidgetComponentConfig) => componentConfig.settings.showTitle

export const isShareOptionsEnabled = (componentConfig: WidgetComponentConfig) =>
  componentConfig.settings.showShareOptions

export const isRsvpTitleEnabled = (componentConfig: WidgetComponentConfig) => componentConfig.settings.showRSVPTitle

export const isRecurringRibbonEnabled = (componentConfig: WidgetComponentConfig, mobile: boolean) =>
  getValue(componentConfig, mobile, 'showRecurringRibbon')

export const isAnyRibbonEnabled = (componentConfig: WidgetComponentConfig, mobile: boolean) =>
  isSoldOutRibbonEnabled(componentConfig, mobile) ||
  isJoinWaitlistRibbonEnabled(componentConfig, mobile) ||
  isRsvpClosedRibbonEnabled(componentConfig, mobile) ||
  isRecurringRibbonEnabled(componentConfig, mobile) ||
  isOfferRibbonEnabled(componentConfig, mobile)

export const isOfferRibbonEnabled = (componentConfig: WidgetComponentConfig, mobile: boolean) =>
  getValue(componentConfig, mobile, 'showOfferRibbon')

export const isMembersEnabled = (componentConfig: WidgetComponentConfig) => componentConfig.settings.showMembers

export const isLocationEnabled = (componentConfig: WidgetComponentConfig) => componentConfig.settings.showLocation

export const isLabelEnabled = (componentConfig: WidgetComponentConfig) => componentConfig.settings.showLabels

export const isImageEnabled = (componentConfig: WidgetComponentConfig) => componentConfig.settings.showImage

export const isHorizontalDividersEnabled = (componentConfig: WidgetComponentConfig) =>
  componentConfig.settings.showHorizontalDividers

export const isHorizontalDateAndLocation = (componentConfig: WidgetComponentConfig) =>
  componentConfig.settings.locationAndDateFormat === LOCATION_AND_DATE_FORMAT.HORIZONTAL

export const isFullDividersEnabled = (componentConfig: WidgetComponentConfig) =>
  componentConfig.settings.showFullDividers

export const isDescriptionEnabled = (componentConfig: WidgetComponentConfig) => componentConfig.settings.showDescription

export const isDateEnabled = (componentConfig: WidgetComponentConfig) => componentConfig.settings.showDate

export const isCountdownEnabled = (componentConfig: WidgetComponentConfig) => componentConfig.settings.showCountdown

export const isCompactDividersEnabled = (componentConfig: WidgetComponentConfig) =>
  componentConfig.settings.showCompactDividers

export const isCompactDateAndLocation = (componentConfig: WidgetComponentConfig) =>
  componentConfig.settings.locationAndDateFormat === LOCATION_AND_DATE_FORMAT.COMPACT

export const isButtonLayout = (componentConfig: WidgetComponentConfig) =>
  isSingleEventWidget(componentConfig) && getWidgetLayout(componentConfig) === WIDGET_LAYOUT.BUTTON_ONLY

export const isBackgroundLayout = (componentConfig: WidgetComponentConfig) =>
  isSingleEventWidget(componentConfig) && getWidgetLayout(componentConfig) === WIDGET_LAYOUT.BACKGROUND

export const getTitleMarginBottom = (componentConfig: WidgetComponentConfig) =>
  componentConfig.settings.titleMarginBottom

export const getRsvpMarginBottom = (componentConfig: WidgetComponentConfig) => componentConfig.settings.rsvpMarginBottom

export const getLocationMarginBottom = (componentConfig: WidgetComponentConfig) =>
  componentConfig.settings.locationMarginBottom

export const getDescriptionMarginBottom = (componentConfig: WidgetComponentConfig) =>
  componentConfig.settings.descriptionMarginBottom

export const getDateMarginBottom = (componentConfig: WidgetComponentConfig) => componentConfig.settings.dateMarginBottom

export const getCountdownMarginBottomCommons = (componentConfig: WidgetComponentConfig) =>
  componentConfig.settings.countdownMarginBottom

export const getDateAndLocationMarginBottom = (componentConfig: WidgetComponentConfig) =>
  componentConfig.settings.dateAndLocationMarginBottom

export const getComponentConfig = (state: State) => state.component

export const isOnImageLayout = (state: State) => isBackgroundLayout(getComponentConfig(state)) && isImageVisible(state)

export const isAllComponentsHidden = (state: State, event: wix.events.Event) =>
  !isCountdownVisible(state) &&
  !isTitleVisible(state) &&
  !isDescriptionVisible(state) &&
  !isDateVisible(state) &&
  !isLocationVisible(state) &&
  !isShareOptionsVisible(state) &&
  !isRibbonVisible(state, event)

export const isOnlyButtonVisible = (state: State) => {
  const event = getFirstEvent(state)
  return isButtonLayout(getComponentConfig(state)) || isAllComponentsHidden(state, event)
}

export const isImageVisible = (state: State) => {
  const event = getFirstEvent(state)
  return isImageEnabled(getComponentConfig(state)) && hasImage(event) && !isOnlyButtonVisible(state)
}

export const isCountdownVisible = (state: State) => {
  const componentConfig = getComponentConfig(state)

  return (
    isCountdownEnabled(componentConfig) &&
    !getFirstEvent(state).scheduling.config.scheduleTbd &&
    !isButtonLayout(componentConfig)
  )
}

export const isTitleVisible = (state: State) => {
  const componentConfig = getComponentConfig(state)
  return isTitleEnabled(componentConfig) && !isButtonLayout(componentConfig)
}

export const isDescriptionVisible = (state: State) => {
  const componentConfig = getComponentConfig(state)

  return isDescriptionEnabled(componentConfig) && !!getFirstEvent(state).description && !isButtonLayout(componentConfig)
}

export const isRibbonVisible = (state: State, event: ExtendedEvent) => {
  const componentConfig = getComponentConfig(state)

  return (
    (isRecurringEvent(event) || !isWithoutRegistration(event) || event.memberOffer) && !isButtonLayout(componentConfig)
  )
}

export const isOfferRibbonVisible = (state: State, event: ExtendedEvent) => event.memberOffer

export const isMembersVisibleInEditor = (state: State) => isEditor(state) && isMembersEnabled(getComponentConfig(state))

export const isMembersVisible = (state: State, event: ExtendedEvent) => {
  const componentConfig = getComponentConfig(state)

  return (
    state.membersEnabled &&
    isInternal(event) &&
    !isButtonLayout(componentConfig) &&
    ((hasGuestsGoing(event.totalGuests, isRsvp(event)) && isMembersEnabled(componentConfig)) ||
      isMembersVisibleInEditor(state))
  )
}

export const isDateVisible = (state: State) => {
  const componentConfig = getComponentConfig(state)
  return isDateEnabled(componentConfig) && !isButtonLayout(componentConfig)
}

export const isLocationVisible = (state: State) => {
  const componentConfig = getComponentConfig(state)
  return isLocationEnabled(componentConfig) && !isButtonLayout(componentConfig)
}

export const isShareOptionsVisible = (state: State) => {
  const componentConfig = getComponentConfig(state)
  return isShareOptionsEnabled(componentConfig) && !isButtonLayout(componentConfig)
}

export const isCompactDividerVisible = (state: State) => {
  const componentConfig = getComponentConfig(state)

  return (
    isCompactDividersEnabled(componentConfig) &&
    isDescriptionEnabled(componentConfig) &&
    isCompactDateAndLocationVisible(state)
  )
}

export const isCompactDateAndLocationVisible = (state: State) =>
  isCompactDateAndLocation(getComponentConfig(state)) && isDateOrLocationVisible(state)

export const isLabelsVisible = (state: State) => isLabelEnabled(getComponentConfig(state))

export const isDateOrLocationVisible = (state: State) => isDateVisible(state) || isLocationVisible(state)

export const isDateAndLocationVisible = (state: State) =>
  !isCompactDateAndLocation(getComponentConfig(state)) && isDateOrLocationVisible(state)

export const getCompactDateAndLocationMargin = (state: State) => {
  if (isMobile(state)) {
    return isDescriptionVisible(state) ? 40 : 30
  }

  return getDateAndLocationMarginBottom(getComponentConfig(state))
}

export const getCountdownMarginBottom = (state: State) =>
  isMobile(state) ? 42 : getCountdownMarginBottomCommons(getComponentConfig(state))

export const isVerticalDividersVisible = (state: State) => {
  const componentConfig = getComponentConfig(state)

  return (
    isFullDividersEnabled(componentConfig) &&
    isHorizontalDateAndLocation(componentConfig) &&
    isDateVisible(state) &&
    isLocationVisible(state)
  )
}

export const isHorizontalDividersVisible = (state: State) => {
  const componentConfig = getComponentConfig(state)
  return isHorizontalDividersEnabled(componentConfig) && isVerticalDateAndLocation(componentConfig)
}

export const getDescriptionMargin = (state: State) => {
  const componentConfig = getComponentConfig(state)

  if (isMobile(state)) {
    return isCompactDateAndLocation(componentConfig) ? 54 : 42
  }

  return getDescriptionMarginBottom(componentConfig)
}

export const getTitleMargin = (state: State) => {
  if (isMobile(state)) {
    return !isDateVisible(state) && !isLocationVisible(state) && !isDescriptionVisible(state) ? 50 : 30
  }

  return getTitleMarginBottom(getComponentConfig(state))
}

export const getDateMargin = (state: State) => {
  const componentConfig = getComponentConfig(state)

  if (isMobile(state)) {
    return isHorizontalDateAndLocation(componentConfig) ? 0 : isLabelsVisible(state) ? 40 : 24
  }

  return isHorizontalDateAndLocation(componentConfig) ? 0 : getDateMarginBottom(componentConfig)
}

export const getRsvpButtonMargin = (state: State) =>
  isOnlyButtonVisible(state) ? 0 : getRsvpMarginBottom(getComponentConfig(state))

export const getLocationMargin = (state: State) => {
  const componentConfig = getComponentConfig(state)
  return isHorizontalDateAndLocation(componentConfig) ? 0 : getLocationMarginBottom(componentConfig)
}

export const isRsvpLabelVisible = (state: State) => isRsvpTitleEnabled(getComponentConfig(state))

export const getRecurringFilter = (state: State) => getComponentConfig(state).settings.recurringFilter

const getValue = (componentConfig: WidgetComponentConfig, mobile: boolean, key: string) => {
  const value = componentConfig.settings[key]

  if (mobile) {
    return getMobileOnlyValue(componentConfig, key) ?? value
  }

  return value
}

const getMobileOnlyValue = (componentConfig: WidgetComponentConfig, key: string) => {
  const deprecatedMobileValue = componentConfig.settings[key + 'Mobile']
  const mobileValue = componentConfig.settings[key + '▶︎m']
  return mobileValue ?? deprecatedMobileValue
}
