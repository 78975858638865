import {isEditor} from '../../../../../../commons/selectors/environment'
import {getCardLocationAndDateFormat, getComponentConfig} from '../../../selectors/settings'
import {getRibbonData} from '../../../selectors/events'
import {isItemOpened} from '../../../selectors/layout'
import {
  isAdditionalComponentsHidden,
  isMembersEnabled,
  isMembersVisible,
  isVenueVisible,
} from '../../../selectors/list-settings'
import {AppProps} from '../../app/interfaces'
import {connect} from '../../runtime-context/connect'
import {Card as CardPresentation} from './card'
import {CardOwnProps, CardStateProps} from './interfaces'

const mapRuntime = ({state, host: {dimensions}}: AppProps, {event}: CardOwnProps): CardStateProps => {
  const componentConfig = getComponentConfig(state)

  return {
    showVenue: isVenueVisible(state, dimensions),
    showMembers: isMembersVisible(state, event),
    membersEnabled: isMembersEnabled(state),
    hideAdditionalComponents: isAdditionalComponentsHidden(state),
    cardLocationAndDateFormat: getCardLocationAndDateFormat(componentConfig),
    hasRibbon: getRibbonData(state, event).visible,
    editor: isEditor(state),
    expanded: isItemOpened(state, event.id),
    compId: state.component.id,
  }
}

export const Card = connect<CardOwnProps, CardStateProps>(mapRuntime)(CardPresentation)

export * from './interfaces'
